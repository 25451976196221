/* eslint-disable no-unused-vars */
import {
  Swiper,
  Pagination,
  Navigation,
  Autoplay,
} from 'swiper';
Swiper.use([Pagination,Navigation,Autoplay]);
import {WOW} from 'wowjs'
import {lightbox} from 'lightbox2'
export default {
  init() {
    // JavaScript to be fired on all pages
    // hero parallax

    

     


    if($('.event-hero').length){
      new WOW().init();
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        $('.event-hero').css({top: -scroll/10+'px'});
        $('.hero-text').css({top: -scroll/15+'px'});
    });



    // mobile nav
    const $hamburger = $('.hamburger');
    const $nav = $('#mobile-nav-wrapper');
    $hamburger.on('click', function (e) {
      e.preventDefault();
      $hamburger.toggleClass('is-active');
      $nav.toggleClass('is-active');
      $('body').toggleClass('is-nav-open');
    });


    //readmore

    $('.read-more-container button').on('click', function (e) {
      e.preventDefault();
      let div = $(this).closest('.read-more-container').find('.readmore-content');
      div.toggleClass('d-none');
      if($(this).html() == 'Close'){
        $(this).html('Read More...')
      }else{
        $(this).html('Close')
      }
    });

    


    // event nav

    $('.mobile-nav a:not(.contact-nav)').on('click', function (e) {
      e.preventDefault();
      let hedaderHeight = $('.event-navigation').outerHeight();
      let id = $(this).attr('href');
      const $hamburger = $('.hamburger');
      const $nav = $('#mobile-nav-wrapper');
      $hamburger.toggleClass('is-active');
      $nav.toggleClass('is-active');
      $('body').toggleClass('is-nav-open');
      $('html, body').animate({
        scrollTop: $(id).offset().top - hedaderHeight - 30,
      }, 1000);
    });


    $('.event-nav a:not(.contact-nav)').on('click', function (e) {
      e.preventDefault();
      let headerHeight = $('.event-navigation').outerHeight();
      let id = $(this).attr('href');
      $('html, body').animate({
        scrollTop: $(id).offset().top - headerHeight - 30,
      }, 1000);
    });

    // faqs
    $('.faq-wrapper button').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('active');
      $(this).closest('.faq-wrapper').find('.answer').slideToggle();
    });

    const gallery = new Swiper('.gallery-event .swiper', {
      spaceBetween: 10,
      freeMode: true,
      slidesPerView: 'auto',
      threshold:15,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    }else{
      // normal pages


    $( document ).ready(function() {
        $('#iframe-container').html('<iframe id="player1" src="https://player.vimeo.com/video/657980410?api=1&player_id=player1&background=1&title=0&byline=0&portrait=0&autoplay=1" height="100%" width="100%"  frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
    });
      

      // mobile nav pages
      const $hamburger = $('.hamburger');
      const $nav = $('#page-mobile-nav');
      $hamburger.on('click', function (e) {
        e.preventDefault();
        $hamburger.toggleClass('is-active');
        $nav.toggleClass('is-active');
        $('body').toggleClass('is-nav-open');
      });

      // mobile nav pages
      const $close = $('#page-mobile-nav .close');
      $close.on('click', function (e) {
        e.preventDefault();
        $hamburger.toggleClass('is-active');
        $nav.toggleClass('is-active');
        $('body').toggleClass('is-nav-open');
      });
      
      $('.mobile-nav a[href*=\\#]').on('click', function (e) {
        e.preventDefault();
        let hedaderHeight = $('.hero-video-header').outerHeight();
        let id = $(this).attr('href');
        $hamburger.toggleClass('is-active');
        $nav.toggleClass('is-active');
        $('body').toggleClass('is-nav-open');
        $('html, body').animate({
          scrollTop: $(id).offset().top - hedaderHeight,
        }, 1000);
      });
      

      $('.hero-video-header a[href*=\\#]').on('click', function (e) {
        e.preventDefault();
        let hedaderHeight = $('.hero-video-header').outerHeight();
        let id = $(this).attr('href');
        $('html, body').animate({
          scrollTop: $(id).offset().top - hedaderHeight,
        }, 1000);
      });

      // testomials slider
      const testimonialsSlider = new Swiper('.testomial-wrapper .swiper', {
        threshold:15,
        slidesPerView: 1,
        spaceBetween: 10,
        speed:1000,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable:true,
        },
      });

      // events slider
    const eventsSlider = new Swiper('.events-slider .swiper', {
      threshold:15,
     
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2.5,
          spaceBetween: 10,
        },
        // when window width is >= 480px
        768: {
          slidesPerView: 3.5,
          spaceBetween: 30,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable:true,
      },

      speed:1000,
      autoplay: {
        delay: 3000,
      },
    });


    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
